import logo from './sisterhood-of-tech-logo.svg';

function App() {
  return (
      <div className=" bg-green-200 max-w-6xl mx-auto mt-16">
        <h1 className="font-sans text-4xl font-bold mb-4">
          Women in Tech Community Groups
        </h1>
        <h2 className="text-2xl">The Best Women-in-Tech Community Groups in Australia</h2>

        <div className="mt-8">
          <iframe class="airtable-embed" src="https://airtable.com/embed/shrvk5Zqygha39ZEg?backgroundColor=cyan&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="533" style={{ background: "transparent", border: "1px solid #ccc;" }}></iframe> 
        </div>
        <div className="mt-8">
          <a className="underline text-purple-800 hover:text-purple-800 mb-4" href="https://airtable.com/shrUnyvgj1yKlSfUl">Submit a WiT Community Group &rarr; </a>
        </div>
      </div>
  );
}

export default App;
